import './BackButton.css';

import { ReactComponent as ArrowLeft } from '@/assets/arrow-left.svg';
import { Button } from '@/components/Button';

type BackButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement> & {
  onClick: () => void;
};

export const BackButton = ({ onClick }: BackButtonProps) => {
  return (
    <Button className="back-btn" onClick={onClick}>
      <ArrowLeft />
    </Button>
  );
};
