import './BookingApp.css';

import { useEffect, useState } from 'react';

import { Loader } from '@/components/Loader';
import { useFreeSlots } from '@/hooks/useFreeSlots';
import { useProviders } from '@/hooks/useProviders';
import { AppointmentTypeSelector } from '@/modules/AppointmentTypeSelector';
import { DoctorFreeSlots } from '@/modules/DoctorFreeSlots';
import { DoctorSelector } from '@/modules/DoctorSelector';
import { useAppointmentsStore } from '@/store/useAppointmentsStore';

export const BookingApp = ({ callback }: { callback?: () => void }) => {
  const [showTimePicker, setShowTimePicker] = useState(false);
  const { isFetching: isFetchingProviders, providers } = useProviders();
  const { isFetching: isFetchingFreeSlots, freeSlots } = useFreeSlots();
  const { clearSelection } = useAppointmentsStore();

  useEffect(() => {
    setShowTimePicker(!isFetchingFreeSlots && !!freeSlots);
  }, [isFetchingFreeSlots, freeSlots]);

  const hasProviders = providers.length > 0;

  if (isFetchingProviders || isFetchingFreeSlots) {
    return <Loader />;
  }

  if (!hasProviders) {
    return <p className="no-results">No results found</p>;
  }

  const closeSlots = () => {
    setShowTimePicker(false);
    clearSelection();
  };

  if (showTimePicker) {
    return (
      <section className="datetime-selector__container">
        <DoctorFreeSlots goBack={closeSlots} callback={callback} />
      </section>
    );
  }

  return (
    <section className="doctor-appointments__container">
      <DoctorSelector />
      <AppointmentTypeSelector />
    </section>
  );
};
