import { useEffect } from 'react';

import useLocalStorage from '@/hooks/useLocalStorage';
import { getGoogleActionsConversionQueryParams } from '@/lib/helpers/urlParams';
import { sendConversionEvent } from '@/services/sendConversionEvent';
import {
  GoogleActionsConversionParams,
  GoogleActionsConversionPayload,
} from '@/types/google-actions.types';

export const useGoogleActionsConversion = () => {
  const [conversionParams, setConversionParams] = useLocalStorage<
    GoogleActionsConversionParams | undefined
  >('conversion_params', getGoogleActionsConversionQueryParams());

  useEffect(() => {
    const { rwg_token, merchant_id } = getGoogleActionsConversionQueryParams();

    if (
      conversionParams?.rwg_token !== rwg_token ||
      conversionParams?.merchant_id !== merchant_id
    ) {
      setConversionParams({
        rwg_token,
        merchant_id,
      });
    }
  }, []);

  const handleConversionEvent = async () => {
    if (!conversionParams) {
      return;
    }

    const conversionPayload: GoogleActionsConversionPayload = {
      conversion_partner_id: conversionParams.merchant_id,
      rwg_token: conversionParams.rwg_token,
      merchant_changed: 2,
    };
    await sendConversionEvent(conversionPayload);
  };

  return {
    conversionParams,
    handleConversionEvent,
  };
};
