import { GoogleActionsConversionPayload } from '@/types/google-actions.types';

const GOOGLE_ACTIONS_CONVERSION_URL = import.meta.env.VITE_GOOGLE_CONVERSION_URL ?? '';

export const sendConversionEvent = async (payload: GoogleActionsConversionPayload) => {
  console.log('Sending conversion event');

  try {
    const res = await fetch(GOOGLE_ACTIONS_CONVERSION_URL, {
      method: 'POST',
      body: JSON.stringify(payload),
    });
    if (res.status !== 200) {
      throw new Error('Failed to send conversion event');
    }
  } catch (err) {
    console.error(err);
  }
};
