import './FullBookingPage.css';

import { useMutation } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';

import { Loader } from '@/components/Loader';
import { useGoogleActionsConversion } from '@/hooks/useGoogleActionsConversion';
import { BookingApp } from '@/modules/BookingApp';
import { BookingLayout } from '@/modules/BookingLayout';
import { ConfirmationScreen } from '@/modules/ConfirmationScreen';
import { PatientInformationForm } from '@/modules/PatientInformationForm';
import { createAppointment } from '@/services/createAppointment';
import { useAppointmentsStore } from '@/store/useAppointmentsStore';
import { CustomerData } from '@/types/customer.types';

export const FullBookingPage = () => {
  const {
    userSelection: {
      provider: selectedProvider,
      appointmentType: selectedAppointmentType,
      meeting: selectedMeeting,
    },
    freeSlots,
    clearSelectedMeeting,
  } = useAppointmentsStore();
  const [showForm, setShowForm] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const { handleConversionEvent } = useGoogleActionsConversion();

  const {
    isPending,
    error,
    data: newAppointment,
    mutate,
  } = useMutation({
    mutationFn: (customer: CustomerData) =>
      createAppointment(
        selectedProvider,
        selectedAppointmentType,
        selectedMeeting,
        freeSlots,
        customer,
      ),
  });

  useEffect(() => {
    if (newAppointment?.confirmed) {
      setShowConfirmation(true);
      clearSelectedMeeting();
      closeFoldOut();
    }
  }, [newAppointment, clearSelectedMeeting]);

  useEffect(() => {
    if (error) {
      toast.error(error.message);
    }
  }, [error]);

  useEffect(() => {
    if (newAppointment?.error) {
      toast.error(newAppointment?.error.toString());
    }
  }, [newAppointment?.error]);

  const closeFoldOut = () => {
    setTimeout(() => {
      window.parent.postMessage(
        JSON.stringify({ action: 'foldOutClose', data: { type: 'iframe' } }),
        '*',
      );
    }, 7500);
  };

  const showPatientForm = () => {
    setShowForm(true);
  };

  const hidePatientForm = () => {
    setShowForm(false);
  };

  const handleSubmit = async (customer: CustomerData) => {
    if (!customer) {
      console.error('Missing patient info');
      return;
    }
    try {
      await mutate(customer);
      await handleConversionEvent();
    } catch (err) {
      console.error(err);
    }
  };

  if (isPending) {
    return (
      <BookingLayout>
        <Loader />
      </BookingLayout>
    );
  }

  if (showConfirmation) {
    return (
      <BookingLayout>
        <ConfirmationScreen appointmentDescription={newAppointment?.description} />
      </BookingLayout>
    );
  }

  return (
    <BookingLayout>
      {!showForm && <BookingApp callback={showPatientForm} />}
      {showForm && <PatientInformationForm goBack={hidePatientForm} callback={handleSubmit} />}
    </BookingLayout>
  );
};
