import { useEffect, useState } from 'react';

const ONE_MONTH_IN_MILLISECONDS = 30 * 24 * 60 * 60 * 1000;

type LocalStorageItem<T> = {
  value: T;
  expireAt: number;
};

export default function useLocalStorage<T>(
  key: string,
  defaultValue: T | null,
  ttl = ONE_MONTH_IN_MILLISECONDS,
): [T | null, (value: T) => void] {
  const [value, setValue] = useState(() => {
    let currentValue;

    try {
      const now = new Date();
      const item: LocalStorageItem<T> | undefined =
        JSON.parse(localStorage.getItem(key) || '') ?? undefined;

      if (item && now.getTime() > item.expireAt) {
        localStorage.removeItem(key);
        return null;
      }
      currentValue = item?.value || defaultValue;
    } catch (error) {
      currentValue = defaultValue;
    }

    return currentValue;
  });

  useEffect(() => {
    const now = new Date();
    const item = {
      value,
      expireAt: now.getTime() + ttl,
    };
    localStorage.setItem(key, JSON.stringify(item));
  }, [value, key, ttl]);

  return [value, setValue];
}
