import * as Sentry from '@sentry/react';
import { Route, Routes } from 'react-router-dom';

import { BookingPage } from '@/pages/BookingPage';
import { FullBookingPage } from '@/pages/FullBookingPage';
import { NotFoundPage } from '@/pages/NotFoundPage';

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

export const MainRouter = () => {
  return (
    <SentryRoutes>
      <Route path="/online" element={<FullBookingPage />} />
      <Route path="/" element={<BookingPage />} />
      <Route path="*" element={<NotFoundPage />} />
    </SentryRoutes>
  );
};
