import './BookingPage.css';

import { hasAllInfoFromQuery } from '@/lib/helpers/urlParams';
import { BookingApp } from '@/modules/BookingApp';

export const BookingPage = () => {
  const hasQueryParams = hasAllInfoFromQuery();

  return (
    <main className="container">
      {hasQueryParams ? <BookingApp /> : <p>Error: missing name, email, phone or location</p>}
    </main>
  );
};
