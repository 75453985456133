import { create, type StateCreator } from 'zustand';

import { DearDocFreeSlots, DearDocProvider } from '@/types/api.types';
import { MeetingSlot } from '@/types/meetings.types';

import { logger } from './logger';

interface AppointmentsState {
  providers: DearDocProvider[];
  freeSlots: DearDocFreeSlots | null;
  userSelection: {
    provider: DearDocProvider | null;
    appointmentType: string | null;
    operatories: string[];
    meeting: MeetingSlot | null;
  };
}

export type AppointmentsStore = AppointmentsState & {
  setProviders: (providers: DearDocProvider[]) => void;
  setFreeSlots: (freeSlots: DearDocFreeSlots | null) => void;
  clearSelection: () => void;
  clearSelectedMeeting: () => void;
  onDoctorSelected: (id: string) => void;
  onAppointmentTypeSelected: (appointmentType: string | null, operatories: string[]) => void;
  onMeetingSelected: (meeting: MeetingSlot | null) => void;
};

export const initialState: Pick<AppointmentsStore, keyof AppointmentsState> = {
  providers: [],
  freeSlots: null,
  userSelection: {
    provider: null,
    appointmentType: null,
    operatories: [],
    meeting: null,
  },
};

const appointmentsSlice: StateCreator<AppointmentsStore> = (set, get) => ({
  ...initialState,
  setProviders: (providers: DearDocProvider[]) => {
    set({ providers });
  },
  setFreeSlots: (freeSlots: DearDocFreeSlots | null) => {
    set({ freeSlots });
  },
  clearSelection: () => {
    set({
      freeSlots: null,
      userSelection: initialState.userSelection,
    });
  },
  clearSelectedMeeting: () => {
    set((state) => ({
      userSelection: {
        ...state.userSelection,
        meeting: null,
      },
    }));
  },
  onDoctorSelected: (id: string) => {
    if (!id || id.length < 1) {
      return;
    }

    const provider = get().providers.find((provider) => provider.id === id) ?? null;
    const appointmentTypesQty = Number(provider?.appointment_types?.length);
    const appointmentType =
      appointmentTypesQty < 2 ? provider?.appointment_types[0]?.id ?? '' : null;

    set({
      userSelection: {
        provider,
        appointmentType,
        operatories: [],
        meeting: null,
      },
    });
  },
  onAppointmentTypeSelected: (appointmentType: string | null, operatories: string[]) => {
    const selectedProvider = get().userSelection?.provider;

    if (!selectedProvider || appointmentType === null) {
      const appointmentTypesQty = Number(selectedProvider?.appointment_types?.length);
      const userSelection =
        appointmentTypesQty < 2 ? initialState.userSelection : get().userSelection;

      set({ freeSlots: null, userSelection });
    }

    set((state) => ({
      userSelection: {
        ...state.userSelection,
        appointmentType,
        operatories,
        meeting: null,
      },
    }));
  },
  onMeetingSelected: (meeting: MeetingSlot | null) => {
    set((state) => ({
      userSelection: {
        ...state.userSelection,
        meeting,
      },
    }));
  },
});

export const useAppointmentsStore = create<AppointmentsStore>()(
  logger<AppointmentsStore>(appointmentsSlice, 'AppointmentsStore'),
);
