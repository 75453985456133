import { getDearDocProviders } from '@/lib/api';
import { getLocationIdFromUrl } from '@/lib/helpers/urlParams';
import { DearDocProvider } from '@/types/api.types';

type FetchProvidersResponse = Promise<{
  error?: string | null;
  providers: DearDocProvider[];
}>;

export const fetchProviders = async (): FetchProvidersResponse => {
  try {
    const locationId = getLocationIdFromUrl();
    if (!locationId) {
      return {
        error: 'Missing or invalid location, please provide a valid ID in the URL',
        providers: [],
      };
    }
    const providers = await getDearDocProviders(locationId);

    return { providers, error: null };
  } catch (e) {
    console.error(e);

    return {
      error: "Sorry, something didn't work fetching the providers",
      providers: [],
    };
  }
};
