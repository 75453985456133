import './Button.css';

import classNames from 'classnames';
import React from 'react';

type ButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement> & {
  children: React.ReactNode;
  isActive?: boolean;
  onClick: () => void;
};

export const Button = ({
  children,
  className,
  onClick,
  isActive = true,
  ...props
}: ButtonProps) => {
  return (
    <button
      className={classNames(
        'btn',
        {
          'btn--active': isActive,
        },
        className,
      )}
      onClick={onClick}
      type="button"
      {...props}
    >
      {children}
    </button>
  );
};
