import './ConfirmationScreen.css';

interface ConfirmationScreenProps {
  appointmentDescription?: string;
}

export const ConfirmationScreen = ({ appointmentDescription }: ConfirmationScreenProps) => {
  return (
    <section className="confirmation__screen">
      <img className="checkmark__img" src="/checkmark.png" alt="checkmark" />
      <p className="confirmation__text">Appointment created successfully</p>
      <p className="confirmation__text">{appointmentDescription}</p>
      <p className="confirmation__text">You can continue safely.</p>
    </section>
  );
};
