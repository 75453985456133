import './PatientInformationForm.css';

import { useState } from 'react';

import { BackButton } from '@/components/BackButton';
import { Button } from '@/components/Button';
import { CustomerData } from '@/types/customer.types';

type PatientInformationFormProps = {
  goBack: () => void;
  callback: (customer: CustomerData) => void;
};

export const PatientInformationForm = ({ goBack, callback }: PatientInformationFormProps) => {
  const [customer, setCustomer] = useState<CustomerData>({
    name: '',
    phone: '',
    email: '',
  });
  const [errors, setErrors] = useState({
    name: '',
    phone: '',
    email: '',
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setCustomer((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: '',
    }));
  };

  const handleSubmit = () => {
    if (!validateForm()) {
      console.error('Form is not valid');
      return;
    }
    console.log('Submitting customer data:', customer);
    callback(customer);
  };

  const validateForm = () => {
    const newErrors = { name: '', phone: '', email: '' };
    let isValid = true;

    if (!customer.name) {
      newErrors.name = 'Name is required.';
      isValid = false;
    }

    if (!customer.phone || customer.phone.length !== 10) {
      newErrors.phone = 'Phone must be 10 digits.';
      isValid = false;
    }

    if (!customer.email || !/\S+@\S+\.\S+/.test(customer.email)) {
      newErrors.email = 'Email is invalid.';
      isValid = false;
    }

    setErrors(newErrors);
    return isValid;
  };

  return (
    <form className="form-container" onSubmit={handleSubmit} noValidate>
      <BackButton onClick={goBack} />

      <p className="form-title">One last step, your patient information</p>
      <div className="form__content">
        <div className="input-container">
          <label htmlFor="name">Name</label>
          <input
            type="text"
            id="name"
            placeholder={'Enter your full name'}
            name="name"
            value={customer.name}
            onChange={handleChange}
          />
          {errors.name && <div className="error">{errors.name}</div>}
        </div>

        <div className="input-container">
          <label htmlFor="email">Email</label>
          <input
            type="email"
            id="email"
            name="email"
            placeholder={'Enter your email'}
            value={customer.email}
            onChange={handleChange}
          />
          {errors.email && <div className="error">{errors.email}</div>}
        </div>

        <div className="input-container">
          <label htmlFor="phone">Phone</label>
          <input
            type="tel"
            id="phone"
            name="phone"
            placeholder={'Enter your phone number'}
            value={customer.phone}
            onChange={handleChange}
          />
          {errors.phone && <div className="error">{errors.phone}</div>}
        </div>
      </div>

      <Button onClick={handleSubmit}>Create Appointment</Button>
    </form>
  );
};
