import './BookingLayout.css';

import { DearDocFooter } from '@/components/DearDocFooter';

const DEARDOC_LOGO =
  'https://firebasestorage.googleapis.com/v0/b/deardoc-appointment-manager.appspot.com/o/DearDoc-logo-dark.png?alt=media&token=24c4aed1-0cc9-46b9-82da-16f9a06c2064';

export const BookingLayout = ({ children }: { children: React.ReactNode }) => {
  return (
    <div className="booking-layout__container">
      <header className="booking-layout__header">
        <img className="booking-layout__logo" src={DEARDOC_LOGO}></img>
        <span className="booking-layout__title">Schedule your appointment</span>
      </header>

      <main className="booking-layout__content">{children}</main>

      <DearDocFooter />
    </div>
  );
};
