import './NotFoundPage.css';

import { BookingLayout } from '@/modules/BookingLayout';

export const NotFoundPage = () => {
  return (
    <BookingLayout>
      <div className="not-found-container">
        <span className="not-found-code">404</span>
        <p className="not-found-title">Page not found</p>
      </div>
    </BookingLayout>
  );
};
