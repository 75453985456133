import { getDearDocFreeSlots } from '@/lib/api';
import { getLocationIdFromUrl } from '@/lib/helpers/urlParams';
import { DearDocFreeSlots, DearDocProvider } from '@/types/api.types';

type FetchFreeSlotsResponse = Promise<{
  error?: string | null;
  freeSlots: DearDocFreeSlots | null;
}>;

export const fetchFreeSlots = async (
  provider: DearDocProvider | null,
  appointmentType: string | null,
): FetchFreeSlotsResponse => {
  try {
    if (!provider) {
      return {
        error: 'Please include a valid provider',
        freeSlots: null,
      };
    }

    const locationId = getLocationIdFromUrl();
    if (!locationId) {
      return {
        error: 'Missing or invalid location, please provide a valid ID in the URL',
        freeSlots: null,
      };
    }
    const freeSlotsFromProvider = await getDearDocFreeSlots(
      locationId,
      provider.id,
      appointmentType,
    );

    if (freeSlotsFromProvider?.slots && freeSlotsFromProvider.slots.length > 0) {
      return {
        freeSlots: freeSlotsFromProvider,
      };
    }

    return {
      error: 'Sorry, there are no free slots available',
      freeSlots: null,
    };
  } catch (e) {
    console.error(e);

    return {
      error: "Sorry, something didn't work getting the doctor's free slots",
      freeSlots: null,
    };
  }
};
