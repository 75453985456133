import * as Sentry from '@sentry/react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { useState } from 'react';
import { Toaster } from 'react-hot-toast';
import { BrowserRouter as Router } from 'react-router-dom';

import { FallbackComponent } from '@/components/FallbackComponent';
import { MainRouter } from '@/router';

function App() {
  const [queryClient] = useState(() => new QueryClient());

  return (
    <Router>
      <Sentry.ErrorBoundary fallback={() => <FallbackComponent />}>
        <QueryClientProvider client={queryClient}>
          <ReactQueryDevtools />
          <MainRouter />
          <Toaster position="bottom-center" />
        </QueryClientProvider>
      </Sentry.ErrorBoundary>
    </Router>
  );
}

export default App;
