import { CustomerData } from '@/types/customer.types';
import { GoogleActionsConversionParams } from '@/types/google-actions.types';

export const getLocationIdFromUrl = (): string | null => {
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.get('location');
};

export const getPatientInfoFromQueryParams = (): CustomerData => {
  const urlParams = new URLSearchParams(window.location.search);
  return {
    name: urlParams.get('name') ?? '',
    email: urlParams.get('email') ?? '',
    phone: urlParams.get('phone') ?? '',
  };
};

export const hasAllInfoFromQuery = (): boolean => {
  const urlParams = new URLSearchParams(window.location.search);
  return (
    !!urlParams.get('location') &&
    !!urlParams.get('name') &&
    !!urlParams.get('email') &&
    !!urlParams.get('phone')
  );
};

export const getGoogleActionsConversionQueryParams = (): GoogleActionsConversionParams => {
  const urlParams = new URLSearchParams(window.location.search);
  const rwg_token = decodeURIComponent(urlParams.get('rwg_token') ?? '');
  const merchant_id = urlParams.get('merchant_id') ?? '';

  return {
    rwg_token,
    merchant_id,
  };
};
